//Testimonials
var swiper = new Swiper('.swiper-container', {
  slidesPerView: 1,
  spaceBetween: 10,
  centeredSlides: false,
  breakpoints: {
    320: {
      slidesPerView: 1,
      centeredSlides: false,
    },

    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: false,
    },
  }
});

//References
var swiper = new Swiper('.swiper-container-references', {
  slidesPerView: 3,
  spaceBetween: 20,
  breakpoints: {
    640: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 5,
    },
    1024: {
      slidesPerView: 5,
    },
  }
});


//Partners-Filter
var $filterCheckboxes = $('input[type="checkbox"]');
$filterCheckboxes.on('change', function () {
  var selectedFilters = {};
  $filterCheckboxes.filter(':checked').each(function () {
    if (!selectedFilters.hasOwnProperty(this.name)) {
      selectedFilters[this.name] = [];
    }
    selectedFilters[this.name].push(this.value);
  });
  var $filteredResults = $('.filterID');
  $.each(selectedFilters, function (name, filterValues) {
    $filteredResults = $filteredResults.filter(function () {
      var matched = false,
        currentFilterValues = $(this).data('category').split(',');
      $.each(currentFilterValues, function (_, currentFilterValue) {

        if ($.inArray(currentFilterValue, filterValues) != -1) {
          matched = true;
          return false;
        }
      });
      return matched;
    });
  });
  $('.filterID').hide().filter($filteredResults).show();
});
//CountUp
$('.count').counterUp({
  delay: 10,
  time: 3000,
});
//Back To Button
$(document).ready(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
      $('#back-to-top').fadeIn();
    } else {
      $('#back-to-top').fadeOut();
    }
  });
  // scroll body to 0px on click
  $('#back-to-top').click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 400);
    return false;
  });
});
